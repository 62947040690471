import React, { useEffect, useState } from "react";
import { loadModules } from "esri-loader";

const locations = [
  { Plats: "Stockholm", X: 673966.677, Y: 6580800.874 },
  { Plats: "Malmö", X: 373972.129, Y: 6164341.497 },
  { Plats: "Uppsala", X: 648191.825, Y: 6638640.255 },
  { Plats: "Östersund", X: 481765.03, Y: 7004644.066 },
  { Plats: "Kumla", X: 508023.175, Y: 6554144.185 },
  { Plats: "Varberg", X: 333403.307, Y: 6332922.066 },
  { Plats: "Lidköping", X: 392981.168, Y: 6486348.056 },
  { Plats: "Alingsås", X: 353877.972, Y: 6423261.169 },
  { Plats: "Båstad", X: 368614.536, Y: 6255438.465 },
  { Plats: "Degerfors", X: 467506.199, Y: 6566664.717 },
];

const MapComponent = () => {
  const [view, setView] = useState(null);
  const [isListVisible, setIsListVisible] = useState(false);

  useEffect(() => {
    loadModules(
      [
        "esri/views/MapView",
        "esri/WebMap",
        "esri/geometry/SpatialReference",
        "esri/geometry/Extent",
        "esri/geometry/Point",
        "esri/geometry/projection",
        "esri/config",
      ],
      { css: true }
    ).then(
      ([
        MapView,
        WebMap,
        SpatialReference,
        Extent,
        Point,
        projection,
        esriConfig,
      ]) => {
        esriConfig.geometryServiceUrl =
          "https://utility.arcgisonline.com/ArcGIS/rest/services/Geometry/GeometryServer";

        const sweref99 = new SpatialReference({ wkid: 3006 });
        const wgs84 = new SpatialReference({ wkid: 4326 });

        const webMap = new WebMap({
          portalItem: {
            id: "74600888eb9a45a8afcd0c35d4be5d58",
          },
        });

        const mapView = new MapView({
          container: "viewDiv",
          map: webMap,
          zoom: 4,
          center: [15, 60],
        });

        projection.load().then(() => {
          setView({
            mapView,
            zoomTo: (x, y) => {
              const point = new Point({ x, y, spatialReference: sweref99 });
              const projectedPoint = projection.project(point, wgs84);
              mapView.goTo({
                center: [projectedPoint.x, projectedPoint.y],
                zoom: 12,
              });
            },
            showAll: () => {
              // Beräkna extenten för alla punkter
              const xs = locations.map((loc) => loc.X);
              const ys = locations.map((loc) => loc.Y);
              const minX = Math.min(...xs);
              const minY = Math.min(...ys);
              const maxX = Math.max(...xs);
              const maxY = Math.max(...ys);

              const extent = new Extent({
                xmin: minX,
                ymin: minY,
                xmax: maxX,
                ymax: maxY,
                spatialReference: sweref99,
              });

              const projectedExtent = projection.project(extent, wgs84);
              mapView.goTo(projectedExtent.expand(1.2)); // Expandera med marginal
            },
          });
        });

        return () => {
          if (mapView) {
            mapView.destroy();
          }
        };
      }
    );
  }, []);

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      {/* Kartan */}
      <div
        id="viewDiv"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      ></div>

      {/* Textrutan med platser */}
      <div
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          padding: "10px",
          borderRadius: "5px",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
          cursor: "pointer",
        }}
        onClick={() => setIsListVisible(!isListVisible)}
      >
        <h4 style={{ margin: "0 0 10px 0", fontSize: "22px" }}>
          Här finns vi:
        </h4>
        {isListVisible && (
          <div>
            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
              <li
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  margin: "5px 0",
                  backgroundColor: "#f0f0f0",
                  borderRadius: "3px",
                  textAlign: "center",
                  transition: "transform 0.2s ease, background-color 0.3s ease",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#e0e0e0";
                  e.currentTarget.style.transform = "translateY(-3px)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#f0f0f0";
                  e.currentTarget.style.transform = "translateY(0)";
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  view && view.showAll();
                }}
              >
                Alla
              </li>
              {locations.map((location, index) => (
                <li
                  key={index}
                  style={{
                    cursor: "pointer",
                    padding: "10px",
                    margin: "5px 0",
                    backgroundColor: "#f0f0f0",
                    borderRadius: "3px",
                    textAlign: "center",
                    transition:
                      "transform 0.2s ease, background-color 0.3s ease",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    view && view.zoomTo(location.X, location.Y);
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = "#e0e0e0";
                    e.currentTarget.style.transform = "translateY(-3px)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = "#f0f0f0";
                    e.currentTarget.style.transform = "translateY(0)";
                  }}
                >
                  {location.Plats}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default MapComponent;
