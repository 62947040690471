import React from "react";
import cloud from "../assets/cloud.svg";
import pilar from "../assets/tt_arrows.svg";
import jordglob from "../assets/jordglob.svg";
import plats from "../assets/Plats.svg";
import rails from "../assets/ralisar.svg";
import slalomrails from "../assets/slalomraÌls.svg";
import train from "../assets/nyttalg.svg";
import validering from "../assets/validering.svg";
import services02 from "../assets/services02.svg";
import services06 from "../assets/services06.svg";
import Header from "./Header";
import Footer from "./Footer";

const geoservices = [
  {
    img: jordglob,
    alt: "Jordglob ikon",
    title: "Geografisk data",
    description:
      "Vi integrerar Asset Care med geografisk data för er anläggning. Geografisk data kan utgöra allt från positionsbestämd anläggnings- eller verksamhetsinformation till olika typer av bakgrundskartor och satellitbilder. Via kopplingen får ni tillgång till inte bara geografiska positioner utan även viktig attributdata som ger ytterligare information om platser och inventarier. En anläggning med bra geografisk data kan även virtualiseras och utgöra din verksamhets digitala tvilling.",
  },
  {
    img: slalomrails,
    alt: "Slalomräls ikon",
    title: "Geografisk analys",
    description:
      "Geografiska data används med fördel för att analysera mönster och samband, som exempelvis positionsförändringar, olika typer av ”hot spots”, alltså platser mad avvikande mönster eller anomalier. Tillgången på geografiska indata bestämmer vilka analyser som är möjliga, vi har exempel på hur man kan analysera förändringar på sub millimeternivå för olika tillämpningar.",
  },
  {
    img: plats,
    alt: "Pin ikon",
    title: "Geografiskt beslutsstöd",
    description:
      "Integrationen med GIS möjliggör att ge visuell förståelse av komplex geografisk information genom presentation av kartor och diagram, vilket underlättar förståelsen av mönster och trender. Den geografiska datan kan även användas till att bedöma risker och sårbarheter i er anläggning.",
  },
  {
    img: rails,
    alt: "Rälsar ikon",
    title: "Laserdatabearbetning och automatisering",
    description:
      "Ett område med växande antal tillämpningar är analys av laserdata eller LiDAR. Extrahering av positionsbestämd information ur laserdata ger oanade möjligheter inom anläggningsinformation såsom asset/inventariebestämning, 3D-modellering och indata för visualisering och digitala tvillingar. Vi är specialister på informationsförädling genom att automatisera laserbearbetningen för en effektiv process från rådata till färdig produkt.",
  },
];

const fordonsunderhall = [
  {
    img: train,
    alt: "Nytt tåg ikon",
    description:
      "Validering av godkänd bestycking av fordon innan trafiksättning. Regelverk och funktion för kontroll och validering för att säkerställa att fordon har godkänd bestyckning innan trafiksättning.",
  },
  {
    img: services02,
    alt: "Document pages with tables on them",
    description:
      "Hjulmåttsregistrering. Funktioner för att kontrollera toleranser och skillnader i hjulgrupper samt visualisering av nuvarande status",
  },
  {
    img: services06,
    alt: "Ikon Projekt",
    description:
      "Mätarbaserade underhållsplaner. Anpassade underhållsplaner (FU-mallar) som dynamiskt skapar arbetsordrar baserat på fordonens körda kilometer, antal dagar sedan service, händelse i systemet m.m",
  },
  {
    img: validering,
    alt: "Validering ikon",
    description:
      "Underhållsintyg. Utfärdande av intyg för utfört underhållsarbete inklusive signering av ansvarig person",
  },
];

const TdAssetCarePage = () => {
  return (
    <main className="bg-dark">
      <Header forceBlackText={true} />
      <section
        id="geodata-services"
        className="ServiceBlock_services__gTaD_ layout-block theme-white"
        style={{ background: "transparent", marginTop: "10vh" }}
      >
        <div className="HeroCard_wrapper__4Q5aw HeroCard_textCentered__HtQ96">
          <h1
            className="HeroCard_headingText__ByzEW"
            style={{ fontSize: "4rem" }}
          >
            TD Asset Care - för alla era EAM behov
          </h1>
          <div className="HeroCard_copy__HvA1B">
            <p className="hero" style={{ fontSize: "1.5rem" }}>
              TD Asset Care är en komplett 360°-lösning för alla era EAM-behov.
              Det är en komplett lösning för att göra förvaltning och underhåll
              av era fysiska tillgångar enkel och effektiv. TD Asset Care
              innehåller branschspecifika funktioner som förbättrar och lägger
              till standardunderhållsfunktionalitet. TD Asset Care kan driftas
              on-prem i era serverhallar, hybrid eller i molnet, allt beroende
              på vad ni har för behov.
            </p>
            <div style={{ marginTop: "2.5rem" }}>
              <a href="/#kontakta-oss">
                <button type="button">Kontakta oss</button>
              </a>
            </div>
          </div>
          <div className="HeroCard_media__7Ubu6"></div>
        </div>
      </section>

      <section
        id="Value-add"
        className="TextMediaBlock_wrapper__fd2J4 theme-lightGrey undefined"
      >
        <div className="TextMediaBlock_content__F8JY_ TextMediaBlock_media-right__34Pzp">
          <div
            className="TextMediaBlock_text__gFT5_"
            style={{ color: "black", background: "transparent" }}
          >
            <div>
              <h2 style={{ fontSize: "4rem" }}>Varför TD Asset Care?</h2>
              <h3>
                <span style={{ fontSize: "1.9rem", fontWeight: 100 }}>
                  TD Asset Care hjälper er att fokusera på planering och
                  upprätthållandet av hög och effektiv produktion.
                </span>
              </h3>
              <h3>
                <span style={{ fontSize: "1.9rem", fontWeight: 100 }}>
                  Ni kan lägga mer tid på produktion och mindre på underhåll.
                </span>
              </h3>
              <h4>
                <span style={{ fontSize: "1.3rem" }}>
                  TD Asset Care är en förstklassig Enterprise Asset
                  Management-lösning med flera komponenter som innehåller
                  specialanpassade funktioner och moduler för att förbättra och
                  förenkla er verksamhet
                </span>
              </h4>
              <ul>
                {[
                  "En fullskalig Enterprise Asset Management (EAM) lösning som hjälper er att hantera och optimera era anläggningstillgångar genom hela deras livscykel",
                  "Asset Care erbjuder en integrerad plattform för att övervaka, underhålla och optimera era fysiska tillgångar, såsom utrustning, infrastruktur och anläggningar",
                  "Geografiska tjänster och funktioner",
                  "Industri- och branschanpassningar för bland annat Järnväg, Pendeltåg, Tunnelbana och fordonsunderhåll",
                  "Anpassad monitorering och dashboards",
                  "Total Digitals-applikationsförvaltning och support enligt ITILs standardprocesser",
                  "Livscykelhantering av hela applikationen",
                  "Vi erbjuder support och hjälp på ert lokala språk",
                ].map((text, index) => (
                  <li key={index}>
                    <span>{text}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div
            className="TextMediaBlock_media__PqTtW undefined"
            style={{
              backgroundColor: "#eaeaea",
              borderRadius: "3rem",
              border: "solid",
              borderWidth: 1,
              borderColor: "#999999",
            }}
          >
            <div className="TextMediaBlock_mediaInner__TNera">
              <span
                style={{
                  boxSizing: "border-box",
                  display: "block",
                  overflow: "hidden",
                  width: "initial",
                  height: "initial",
                  background: "none",
                  opacity: 1,
                  border: 0,
                  margin: 0,
                  padding: 0,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                }}
              >
                <img
                  alt="Pilar"
                  src={pilar}
                  decoding="async"
                  data-nimg="fill"
                  style={{
                    backgroundColor: "#eaeaea",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    boxSizing: "border-box",
                    padding: 0,
                    border: "none",
                    margin: "auto",
                    display: "block",
                    width: 0,
                    height: 0,
                    minWidth: "100%",
                    maxWidth: "100%",
                    minHeight: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                />
              </span>
            </div>
          </div>
        </div>
      </section>

      <section
        id="geodata-services"
        className="ServiceBlock_services__gTaD_ layout-block theme-white"
        style={{ background: "transparent", marginTop: "10vh" }}
      >
        <div className="ServiceBlock_container__ikEu8">
          <div className="ServiceBlock_intro__FIZTe">
            <h2>Geografiska tjänster och funktioner</h2>
          </div>
          <div className="ServiceBlock_serviceList__3PytR">
            {geoservices.map((service, index) => (
              <div className="ServiceCard_wrapper_noclick" key={index}>
                <div className="ServiceCard_media__f8aI1">
                  <img
                    src={service.img}
                    width="146.31"
                    height="158.42"
                    alt={service.alt}
                  />
                </div>
                <div className="ServiceCard_text__oy4C1">
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="overflow-hidden theme-white" id="vehicle">
        <div className="PartnerBlock_container__nL2br">
          <div className="PartnerBlock_background__BMaCP">
            <span
              style={{
                boxSizing: "border-box",
                display: "inline-block",
                overflow: "hidden",
                width: "initial",
                height: "initial",
                background: "none",
                opacity: 1,
                border: 0,
                margin: 0,
                padding: 0,
                position: "relative",
                maxWidth: "100%",
              }}
            >
              <span
                style={{
                  boxSizing: "border-box",
                  display: "block",
                  width: "initial",
                  height: "initial",
                  background: "none",
                  opacity: 1,
                  border: 0,
                  margin: 0,
                  padding: 0,
                  maxWidth: "100%",
                }}
              >
                <img
                  style={{
                    display: "block",
                    maxWidth: "100%",
                    width: "initial",
                    height: "initial",
                    background: "none",
                    opacity: 1,
                    border: 0,
                    margin: 0,
                    padding: 0,
                  }}
                  alt=""
                  aria-hidden="true"
                  src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27899.99957%27%20height=%27750%27/%3e"
                />
              </span>
              <img
                alt="Gammalt tåg bakgrund"
                role="presentation"
                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                decoding="async"
                data-nimg="intrinsic"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  boxSizing: "border-box",
                  padding: 0,
                  border: "none",
                  margin: "auto",
                  display: "block",
                  width: 0,
                  height: 0,
                  minWidth: "100%",
                  maxWidth: "100%",
                  minHeight: "100%",
                  maxHeight: "100%",
                }}
              />
              <noscript>
                <img
                  alt="Gammalt tåg bakgrund"
                  role="presentation"
                  loading="lazy"
                  decoding="async"
                  data-nimg="intrinsic"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    boxSizing: "border-box",
                    padding: 0,
                    border: "none",
                    margin: "auto",
                    display: "block",
                    width: 0,
                    height: 0,
                    minWidth: "100%",
                    maxWidth: "100%",
                    minHeight: "100%",
                    maxHeight: "100%",
                  }}
                  srcSet="https://admin.totaldigital.se/media/gammalttaÌg_bakgrund.svg 1x, https://admin.totaldigital.se/media/gammalttaÌg_bakgrund.svg 2x"
                  src="https://admin.totaldigital.se/media/gammalttaÌg_bakgrund.svg"
                />
              </noscript>
            </span>
          </div>
          <div className="PartnerBlock_intro__hftvr">
            <h2>Fordonsunderhåll</h2>
            <p>
              TD Asset Care inkluderar resultatet av vår långa erfarenhet inom
              underhållsarbete för spårfordon. Några av de branschspecifika
              funktioner vi erbjuder är följande:
            </p>
          </div>
          <div className="PartnerBlock_partnerList__eSc1f">
            {fordonsunderhall.map((partner, index) => (
              <div
                className="PartnerCard_wrapper__uEF8L PartnerCard_size-xs__EZGyQ"
                key={index}
              >
                <div className="PartnerCard_media__c2yb6">
                  <div className="PartnerCard_svgWrapper__LJHD9">
                    <img
                      src={partner.img}
                      alt={partner.alt}
                      width="146.31"
                      height="158.42"
                    />
                  </div>
                </div>
                <div className="PartnerCard_text__dpbgP">
                  <p>
                    <span>{partner.description}</span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default TdAssetCarePage;
