import React from "react";
import cloud from "../assets/cloud.svg";
import jordglob from "../assets/jordglob.svg";
import plats from "../assets/Plats.svg";
import rails from "../assets/ralisar.svg";
import slalomrails from "../assets/slalomraÌls.svg";
import Header from "./Header";
import Footer from "./Footer";
import { geodataServiceTexts } from "../texts/texts";

const images = [jordglob, slalomrails, plats, rails];

const GeodataPage = () => {
  return (
    <main className="bg-dark">
      <Header forceBlackText={true} />
      <section
        id="geodata-services"
        className="ServiceBlock_services__gTaD_ layout-block theme-white"
        style={{ background: "transparent", marginTop: "10vh" }}
      >
        <div className="ServiceBlock_container__ikEu8">
          <div className="ServiceBlock_intro__FIZTe">
            <h2>{geodataServiceTexts.header}</h2>
          </div>
          <div className="ServiceBlock_serviceList__3PytR">
            {geodataServiceTexts.services.map((service, index) => (
              <div className="ServiceCard_wrapper_noclick" key={index}>
                <div className="ServiceCard_media__f8aI1">
                  <img
                    src={images[index]}
                    width="146.31"
                    height="158.42"
                    alt={service.alt}
                  />
                </div>
                <div className="ServiceCard_text__oy4C1">
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                </div>
              </div>
            ))}
            <div
              className="ServiceBlock_media__sKnGJ"
              style={{ left: "-200px" }}
            >
              <img src={cloud} alt="Moln" width="330.455" height="209.29" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default GeodataPage;
