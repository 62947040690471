import React from "react";
import { aboutTexts } from "../texts/texts";
import "../styles/about.css";
import MapComponent from "./MapComponent";
import techimg from "../assets/tech-icon.svg";

const About = () => {
  return (
    <div style={{ marginBottom: "35rem", marginTop: "2rem" }}>
      <section
        id="om-total-digital"
        className="about-container theme-lightGrey"
      >
        <div className="TextMediaBlock_content TextMediaBlock_media-left">
          {/* Map Section */}
          <div className="map">
            <MapComponent />
          </div>

          {/* Text Section */}
          <div className="about-textblock TextMediaBlock_text">
            <div>
              <h2 style={{ fontSize: "50px" }}>{aboutTexts.header}</h2>
              {aboutTexts.content.map((paragraph, index) => (
                <div style={{ paddingBottom: "1.5rem" }} key={index}>
                  <div style={{ fontSize: "1.4rem" }}>{paragraph}</div>
                </div>
              ))}
            </div>
          </div>

          {/* Technology Image */}
          <div className="tech-image">
            <img src={techimg} alt="Tech Illustration" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
