import React from "react";
import "../styles/buisnesses.css";
import services01 from "../assets/gear.svg";
import services02 from "../assets/gear.svg";
import services03 from "../assets/gear.svg";
import services04 from "../assets/gear.svg";
import circleSvg from "../assets/circle.svg";
import Header from "./Header";
import Footer from "./Footer";
import { fleetmanagement } from "../texts/texts";

const images = [services01, services02, services03, services04];

const FleetManagement = () => {
  return (
    <main className="buisnesses-page">
      <Header forceBlackText={true} />
      <section className="buisnesses-hero">
        <div className="buisnesses-hero-content">
          <h1>{fleetmanagement.header}</h1>
          <p>{fleetmanagement.desc1}</p>
          <p>{fleetmanagement.desc2}</p>
        </div>
      </section>

      <div className="circle-background">
        <img src={circleSvg} alt="Circle Background" />
      </div>

      <section className="buisnesses-cards">
        <div className="buisnesses-cards-container">
          {fleetmanagement.services.map((service, index) => (
            <div className="buisnesses-card" key={index}>
              <div className="buisnesses-card-image">
                <img src={images[index % images.length]} alt={service.title} />
              </div>
              <div className="buisnesses-card-content">
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <Footer />
    </main>
  );
};

export default FleetManagement;
