import React from "react";
import "../styles/footer.css";
import linkedinlogo from "../assets/icon-linkedin.svg";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../assets/animatedsvgs/Total_Digital_Logo_effect.json";
import { footerTexts } from "../texts/texts";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.playerRef = React.createRef();
  }

  handleComplete = () => {
    setTimeout(() => {
      if (this.playerRef.current) {
        this.playerRef.current.play();
      }
    }, 1000);
  };

  render() {
    return (
      <footer className="footer-container">
        <div className="footer-content">
          <div className="footer-column">
            <div className="footer-column-left">
              <h4>{footerTexts.header}</h4>
              <p>{footerTexts.address.name}</p>
              <p>{footerTexts.address.street}</p>
              <p>{footerTexts.address.city}</p>
              <br />
              {footerTexts.contacts.map((contact, index) => (
                <p key={index}>
                  <a
                    href={contact.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {contact.type}: {contact.value}
                  </a>
                </p>
              ))}
            </div>
          </div>

          <div className="footer-column">
            <div className="footer-column-center">
              <h4>{footerTexts.followUs.header}</h4>
              <div className="linkedin">
                <a
                  href={footerTexts.followUs.linkedin.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt={footerTexts.followUs.linkedin.alt}
                    style={{ cursor: "pointer" }}
                    src={linkedinlogo}
                  ></img>
                </a>
              </div>
            </div>
          </div>

          <div className="footer-column">
            <div className="footer-column-right">
              <h4>{footerTexts.shortcuts.header}</h4>
              <ul>
                {footerTexts.shortcuts.links.map((link, index) => (
                  <li key={index}>
                    <p>
                      <a href={link.link}>{link.name}</a>
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-bottom-line"></div>
          <div className="footer-logo">
            <Player
              ref={this.playerRef}
              autoplay
              loop={false}
              controls={true}
              src={animationData}
              style={{
                filter: "invert(1)",
                opacity: 0.25,
              }}
              onEvent={(event) => {
                if (event === "complete") {
                  this.handleComplete();
                }
              }}
            ></Player>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
