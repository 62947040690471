import React, { useState } from "react";
import { buisnessTexts } from "../texts/texts";
import "../styles/buisness.css";

interface BusinessProps {
  title: string;
  description: string;
  imageSrc: string;
  details: {
    desc1: string;
    desc2: string;
  };
}

const availableImages = {
  Infrastruktur: require("../assets/sthlm3.png"),
  Industri: require("../assets/Maximo1.png"),
  Fastighet: require("../assets/maximo3.png"),
  FleetManagement: require("../assets/sthlm1.png"),
};

const BusinessSection: React.FC = () => {
  const [flippedCards, setFlippedCards] = useState<{ [key: number]: boolean }>(
    {}
  );

  const businessItems: BusinessProps[] = buisnessTexts.buisnessSubMenu.map(
    (item) => {
      const foundDesc = buisnessTexts.buisnessDesc.find(
        (desc) => desc.label === item.label
      );

      return {
        title: item.label,
        description: foundDesc?.desc1 || "Beskrivning ej tillgänglig",
        imageSrc:
          availableImages[item.label as keyof typeof availableImages] || "",
        details: {
          desc1: foundDesc?.desc1 || "",
          desc2: foundDesc?.desc2 || "",
        },
      };
    }
  );

  const handleFlip = (index: number) => {
    setFlippedCards((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <section className="business-section">
      <h2>Affärsområden</h2>
      <div className="business-cards-wrapper">
        {businessItems.map((item, index) => (
          <div
            key={index}
            className={`business-card ${flippedCards[index] ? "flipped" : ""}`}
            onClick={() => handleFlip(index)}
          >
            {/* Framsidan */}
            <div className="card-front">
              <div className="business-card-image">
                <img src={item.imageSrc} alt={item.title} />
              </div>
              <div className="business-card-content">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
            </div>

            {/* Baksidan */}
            <div className="card-back">
              <div className="business-card-content">
                <h3>{item.title}</h3>
                <p>{item.details.desc1}</p>
                <div
                  dangerouslySetInnerHTML={{ __html: item.details.desc2 }}
                ></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default BusinessSection;
