import React, { useEffect } from "react";
import Hero from "./Hero";
import Services from "./Services";
import Products from "./Products";
import Buisness from "./Buisness";
import Partners from "./Partners";
import About from "./About";
import ContactForm from "./ContactForm";
import Footer from "./Footer";

function HomePage() {
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    if (window.location.hash) {
      handleHashChange();
    }

    window.addEventListener("hashchange", handleHashChange);
    return () => window.removeEventListener("hashchange", handleHashChange);
  }, []);

  return (
    <>
      <section id="hero">
        <Hero />
      </section>
      <section id="buisness">
        <Buisness />
      </section>
      <section id="products">
        <Products />
      </section>
      <section id="services">
        <Services />
      </section>
      <section id="partners">
        <Partners />
      </section>
      <section id="about">
        <About />
      </section>
      {/* <section id="contact">
        <ContactForm />
      </section> */}
      <section id="footer">
        <Footer />
      </section>
    </>
  );
}

export default HomePage;
