import React, { useState } from "react";
import "../styles/servicesStyle.css";
import services01 from "../assets/services01.svg";
import services02 from "../assets/services02.svg";
import services03 from "../assets/services03.svg";
import services04 from "../assets/services04.svg";
import services05 from "../assets/services05.svg";
import services06 from "../assets/services06.svg";
import services07 from "../assets/services07.svg";
import rails from "../assets/ralisar.svg";
import { serviceTexts } from "../texts/texts";

const availableServiceImages = [
  services01,
  services04,
  rails,
  services02,
  services03,
  services06,
  services07,
  services05,
];

interface ServiceCardProps {
  title: string;
  description: string;
  imageSrc: string;
  onClick: () => void;
  expanded: boolean;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  title,
  description,
  imageSrc,
  onClick,
  expanded,
}) => {
  return (
    <div
      className={`services-card ${expanded ? "expanded" : ""}`}
      onClick={onClick}
    >
      <div className="services-card-image">
        <img src={imageSrc} alt={title} />
      </div>
      <div className="services-card-content">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

const Services: React.FC = () => {
  const [expandedCardIndex, setExpandedCardIndex] = useState<number | null>(
    null
  );

  const serviceCards = serviceTexts.serviceDescriptions.map((text, index) => ({
    ...text,
    imageSrc: availableServiceImages[index],
  }));

  const handleCardClick = (index: number) => {
    setExpandedCardIndex((prev) => (prev === index ? null : index));
  };

  return (
    <>
      {/* Overlay */}
      <div
        className={`services-overlay ${
          expandedCardIndex !== null ? "active" : ""
        }`}
        onClick={() => setExpandedCardIndex(null)}
      ></div>

      <section className="services-section">
        <h2>{serviceTexts.header}</h2>

        <div className="services-cards-wrapper">
          {serviceCards.map((card, index) => (
            <ServiceCard
              key={index}
              title={card.title}
              description={card.description}
              imageSrc={card.imageSrc}
              onClick={() => handleCardClick(index)}
              expanded={expandedCardIndex === index}
            />
          ))}
        </div>
      </section>
    </>
  );
};

export default Services;
