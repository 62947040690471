import React, { useEffect, useState } from "react";
import "react-image-lightbox/style.css";
import tdtestlogo from "../assets/TDTEST_logo.svg";
import exempeltest from "../assets/exempel-test.png";
import rapportering from "../assets/rapportering.png";
import automatiserade from "../assets/automatiserade.png";
import speed from "../assets/speed.svg";
import safe from "../assets/safe.svg";
import money from "../assets/money.svg";
import userfriendly from "../assets/user.svg";
import gear from "../assets/gear.svg";
import "../styles/TdTest.css";
import { Link, useNavigate } from "react-router-dom";
import { IconCheck, IconNoCheck } from "../components/Icon";
import { tdTestTexts } from "../texts/texts";
import "../styles/TdTest.css";
import ContactForm from "./ContactForm";
import Footer from "./Footer";

const TdTest = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    mobile: "",
    company: "",
    message: "",
    gdprConsenttdtest: false,
  });

  const handleImageClick = (src) => {
    console.log("Image clicked:", src); // Kontrollera att denna körs
    setSelectedImage(src);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    if (window.location.pathname === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      navigate("/");
      setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 0);
    }
  };

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      console.warn(`Element med id="${id}" hittades inte.`);
    }
  };

  const handleNavigation = (id) => {
    handleScroll(id);
  };

  const formatTextWithStrong = (text, wordsToBold = []) => {
    const regex = new RegExp(`(${wordsToBold.join("|")})`, "g");
    return text
      .split(regex)
      .map((part, index) =>
        wordsToBold.includes(part) ? <strong key={index}>{part}</strong> : part
      );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://totaldigital.info/public/send_email.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      )
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => console.error("Error:", error));

      const result = await response.json();
      if (result.success) {
        alert("Meddelandet har skickats!");
      } else {
        alert("Ett fel uppstod: " + result.message);
      }
    } catch (error) {
      alert("Ett fel uppstod vid anslutningen till servern.");
    }
  };

  return (
    <body className="tdtest">
      <section id="Hero" className="layout-block theme-tdtest">
        <div className="HeroCard_wrapper HeroCard_textCentered">
          <h1 className="HeroCard_headingText">
            {formatTextWithStrong(tdTestTexts.hero.header)}
          </h1>
          <div className="HeroCard_copy">
            <p className="hero">
              {formatTextWithStrong(tdTestTexts.hero.description)}
            </p>
            <div style={{ marginTop: "2.5rem" }}>
              <a href="mailto:info@totaldigital.se">
                <button type="button">{tdTestTexts.hero.buttonText}</button>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        id="vadartdtest"
        className="TextMediaBlock_wrapper theme-tdtest TextMediaBlock_tdtest"
      >
        <div className="TextMediaBlock_content TextMediaBlock_media-right">
          <div className="TextMediaBlock_text">
            <div>
              <h2>{formatTextWithStrong(tdTestTexts.about.header)}</h2>
              {tdTestTexts.about.paragraphs.map((para, index) => (
                <p key={index}>{formatTextWithStrong(para)}</p>
              ))}
            </div>
          </div>
          <div className="TextMediaBlock_media">
            <div className="TextMediaBlock_mediaInner">
              <img
                alt="Pilar"
                src={tdtestlogo}
                decoding="async"
                data-nimg="fill"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  boxSizing: "border-box",
                  padding: 0,
                  border: "none",
                  margin: "auto",
                  display: "block",
                  width: 0,
                  height: 0,
                  minWidth: "100%",
                  maxWidth: "100%",
                  minHeight: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <section id="cards" className="layout-block theme-tdtest">
        <div className="CardBlock_content">
          <div className="CardBlock_intro">
            <h2>
              {formatTextWithStrong(tdTestTexts.sections.capabilities.header)}
            </h2>
          </div>
          <div className="CardBlock_cardList">
            {tdTestTexts.sections.capabilities.items.map((item, index) => (
              <div className="Card_wrapper" key={index}>
                <div>
                  <div className="Card_heading">
                    <h3>{formatTextWithStrong(item.title)}</h3>
                  </div>
                  <div className="Card_text">
                    {/* Visa första raden som underrubrik med vissa ord i fetstil */}
                    <p className="Card_subtitle">
                      {formatTextWithStrong(item.content[0], ["TD Test"])}
                    </p>
                    {/* Rendera resten som lista med specifika ord i fetstil */}
                    <ul>
                      {item.content.slice(1).map((content, idx) => (
                        <li key={idx}>{content}</li>
                      ))}
                    </ul>
                  </div>

                  {/* Bildvisning och andra komponenter */}
                  {index === 2 && (
                    <div className="Card_image">
                      <img
                        src={rapportering}
                        alt="Rapportering"
                        className="responsive-image"
                        onClick={() => handleImageClick(rapportering)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  )}
                  {index === 3 && (
                    <div className="Card_image">
                      <img
                        src={automatiserade}
                        alt="Automatiserade tester"
                        className="responsive-image"
                        onClick={() => handleImageClick(automatiserade)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="funktioner" className="undefined layout-block theme-tdtest">
        <div className="CardBlock_content">
          <div className="CardBlock_intro">
            <h2>{tdTestTexts.sections.features.header}</h2>
          </div>
          <div className="CardBlock_cardList">
            {tdTestTexts.sections.features.items.map((feature, index) => (
              <div className="Card_wrapper" key={index}>
                <div className="Card_iconContainer">
                  <img
                    className="Card_icon-small responsive-image"
                    src={
                      index === 0
                        ? gear
                        : index === 1
                        ? speed
                        : index === 2
                        ? safe
                        : index === 3
                        ? money
                        : userfriendly
                    }
                    alt={`${feature.title} icon`}
                  />
                </div>
                <div>
                  <div className="Card_heading">
                    <h3>{feature.title}</h3>
                  </div>

                  {/* Speciell hantering för "Användarvänligt" och "Exempel på test" */}
                  <div className="Card_text">
                    {feature.title === "Användarvänligt" ? (
                      <>
                        <p>
                          <strong>{tdTestTexts.TDTest}</strong>{" "}
                          {feature.description1}
                        </p>
                        <ul>
                          <li>
                            {formatTextWithStrong(feature.description2, [
                              "TD Test",
                            ])}
                          </li>
                          <li>{feature.description3}</li>
                          <li>{feature.description4}</li>
                          <li>{feature.description5}</li>
                          <li>{feature.description6}</li>
                          <li>{feature.description7}</li>
                        </ul>
                      </>
                    ) : feature.title === "Exempel på test" ? (
                      <div
                        className="Card_image"
                        style={{ paddingLeft: "0px !important" }}
                      >
                        <img
                          src={exempeltest}
                          alt="Exempel på test"
                          onClick={() => handleImageClick(exempeltest)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    ) : (
                      <p>
                        {formatTextWithStrong(feature.description, ["TD Test"])}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="Jämförelse" className="layout-block theme-tdtest">
        <div className="PromoTable_promotableContainer">
          <div className="PromoTable_promotableTitles">
            <h3>{tdTestTexts.comparison.header}</h3>
          </div>
          <div className="PromoTable_promotableHeader">
            {tdTestTexts.comparison.columns.map((column, index) => (
              <div key={index} className="">
                <p>{column}</p>
              </div>
            ))}
          </div>
          <div className="PromoTable_promotableRows">
            {tdTestTexts.comparison.features.map((feature, index) => (
              <div key={index} className="PromoTable_promoTableRow">
                <div className="">
                  <p>{feature.description}</p>
                </div>
                <div className="">
                  {feature.webdriver ? <IconCheck /> : <IconNoCheck />}
                </div>
                <div className="">
                  {feature.tdTest ? <IconCheck /> : <IconNoCheck />}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {modalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close-button" onClick={closeModal}>
              X
            </button>
            <img src={selectedImage} alt="Visning" />
          </div>
        </div>
      )}

      <Footer />
    </body>
  );
};

export default TdTest;
