import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/Total_Digital_Logo.svg";
import { headerTexts, buisnessTexts } from "../texts/texts";
import "../styles/header.css";

const Header: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);

  const handleScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    setMenuOpen(false); // Stäng menyn på mobila enheter
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleDropdown = (id: string) => {
    setActiveDropdown((prev) => (prev === id ? null : id));
  };

  const menuItems = [
    {
      label: headerTexts.menuItems.main[0].label,
      link: "#buisness",
      // subMenu: buisnessTexts.buisnessSubMenu,
    },
    {
      label: headerTexts.menuItems.main[1].label,
      link: "#products",
      // subMenu: headerTexts.menuItems.subMenu,
    },
    {
      label: headerTexts.menuItems.main[2].label,
      link: "#services",
      // subMenu: headerTexts.menuItems.subMenu,
    },

    { label: headerTexts.menuItems.main[3].label, link: "#partners" },
    { label: headerTexts.menuItems.main[4].label, link: "#about" },
    { label: headerTexts.menuItems.main[5].label, link: "#footer" },
  ];

  return (
    <header className={`header-container ${scrolled ? "scrolled" : ""}`}>
      <div className="header-content">
        <div className="logo-wrapper">
          <Link
            to="/"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              setMenuOpen(false);
            }}
          >
            <img src={logo} alt={headerTexts.logoAlt} className="logo" />
          </Link>
          {isMobile && (
            <button
              className="menu-toggle"
              aria-label="Toggle menu"
              onClick={toggleMenu}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                width="24"
                height="24"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </button>
          )}
        </div>

        <nav className={`menu ${menuOpen ? "open" : ""}`}>
          <ul className="menu-items">
            {menuItems.map((item, index) => (
              <li
                key={index}
                className="menu-item"
                onMouseEnter={() => handleDropdown(item.label)}
                onMouseLeave={() => setActiveDropdown(null)}
                onClick={() => handleScroll(item.link.substring(1))}
              >
                <Link to={item.link}>{item.label}</Link>
                {/* {item.subMenu && activeDropdown === item.label && (
                  <ul className="submenu">
                    {item.subMenu.map((subItem, subIndex) => (
                      <li key={subIndex}>
                        <Link to={subItem.link}>{subItem.label}</Link>
                      </li>
                    ))}
                  </ul>
                )} */}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
