import React, { useState, useEffect } from "react";
import video1 from "../assets/videos/aerial-top-view-drone-4k.mov";
import video2 from "../assets/videos/train-railway.mov";
import video3 from "../assets/videos/strategy-business-technology.mp4";
import video4 from "../assets/videos/real-estate-agent-house-model.MOV";

const videos = [
  {
    src: video1,
    title: "Utforska geografiska möjligheter",
    subText: "Vi erbjuder omfattande tjänster och lösningar inom geodata.",
  },
  {
    src: video2,
    title: "Förebyggande underhåll",
    subText: "Optimera och effektivisera verksamheten med TD Asset Care.",
  },
  {
    src: video3,
    title: "Kvalitetssäkring med TD Test",
    subText: "Förbättra processer och säkerställ kvalitet i varje steg.",
  },
  {
    src: video4,
    title: "Bättre projektering",
    subText: "Modernisera anläggningsverksamheten med avancerade lösningar.",
  },
];

const HeroSection = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
    }, 8000); // Ändrar video var 8:e sekund
    return () => clearInterval(interval);
  }, []);

  return (
    <section className="hero-section">
      <div className="video-container">
        {videos.map((video, index) => (
          <video
            key={index}
            src={video.src}
            autoPlay
            muted
            loop
            playsInline
            className={`video-background ${
              index === currentVideoIndex ? "active" : ""
            }`}
          ></video>
        ))}
        <div className="overlay"></div> {/* Gradient overlay */}
        <div className="overlay-content">
          <h1>{videos[currentVideoIndex].title}</h1>
          <p>{videos[currentVideoIndex].subText}</p>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
