import React from "react";
import IBM_logo from "../assets/IBM_logo.svg";
import ESRI_logo from "../assets/ESRI_logo.png";
import PED_Logo_RGB_L from "../assets/PED_Logo_RGB_L.svg";
import bpd_logo from "../assets/bpd_logo.svg";
import maxanv_logo from "../assets/maxanv_logo.svg";
import circle from "../assets/circle.svg";
import { partnerTexts } from "../texts/texts";
import "../styles/partners.css";

const Partners = () => {
  const partnerImages = [
    IBM_logo,
    ESRI_logo,
    PED_Logo_RGB_L,
    bpd_logo,
    maxanv_logo,
  ];

  return (
    <section className="partners-section" id="partners">
      <div className="partners-container">
        <div className="partners-circle">
          <img
            alt="circle"
            role="presentation"
            src={circle}
            decoding="async"
            data-nimg="intrinsic"
          />
        </div>

        <div className="partners-intro">
          <h2>{partnerTexts.header}</h2>
          <p>{partnerTexts.introText}</p>
        </div>

        <div className="partners-list">
          {partnerTexts.partners.map((partner, index) => (
            <div key={partner.name} className="partner-card">
              <div className="partner-card-media">
                <div className="partner-card-image-wrapper">
                  <a
                    className="partner-card-link"
                    tabIndex="-1"
                    href={partner.link}
                  >
                    <img src={partnerImages[index]} alt={partner.alt} />
                  </a>
                </div>
              </div>
              <div className="partner-card-text">
                <a href={partner.link}>
                  <p>{partner.description}</p>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partners;
