import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/products.css";
import { tdAssetCareTexts, tdTestTexts } from "../texts/texts";

import maximoimg3 from "../assets/912-ibm-maximo-1043x326.jpg";
import maximoimg4 from "../assets/912-ibm-maximo-1043x326.jpg";

const areas = [
  {
    title: tdAssetCareTexts.header,
    description: tdAssetCareTexts.intro,
    details: [tdAssetCareTexts.reasonsHeader, ...tdAssetCareTexts.reasons],
    image: maximoimg3,
    isRedirect: false,
  },
  {
    title: tdTestTexts.hero.header,
    description: tdTestTexts.hero.description,
    redirectPath: "/tdtest",
    image: maximoimg4,
    isRedirect: true,
  },
];

const LargeCardsSection = () => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const navigate = useNavigate();

  const handleExpand = (index: number, redirectPath?: string) => {
    if (redirectPath) {
      navigate(redirectPath);
    } else {
      setExpandedIndex(index === expandedIndex ? null : index);
    }
  };

  return (
    <section className="large-cards-section">
      <h2>Produkter</h2>
      {areas.map((area, index) => (
        <div
          key={index}
          className={`large-card ${expandedIndex === index ? "expanded" : ""}`}
        >
          <div className="large-card-image">
            <img src={area.image} alt={area.title} />
          </div>
          <div className="large-card-content">
            <h2>{area.title}</h2>
            <p>{area.description}</p>
            {expandedIndex === index && !area.isRedirect && (
              <ul>
                {area.details?.map((detail, i) => (
                  <li key={i}>{detail}</li>
                ))}
              </ul>
            )}
            <button
              className="read-more-button"
              onClick={() => handleExpand(index, area.redirectPath)}
            >
              {area.isRedirect
                ? "Läs mer"
                : expandedIndex === index
                ? "Visa mindre"
                : "Läs mer"}
            </button>
          </div>
        </div>
      ))}
    </section>
  );
};

export default LargeCardsSection;
